import React from 'react'
import Link from "next/link";
import Image from "next/image";
import styles from "../styles/components/Footer.module.scss";
import Script from 'next/script';
import {
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaYoutube
} from "react-icons/fa";
import Chatbot from './Facebook/chatbot';
import TripAdvisorWidget from './TripAdvisorWidget';

function Footer() {

    const contact = [
        // {
        //     title: "+91 9317192605",
        //     hyperLink: "tel:9317192605",
        //     icon: "/icons/call-gray.svg"
        // },
        {
            title: "NH 05, New kufri, Galu, Hills, Shimla, Himachal Pradesh, India 171209",
            hyperLink: "https://g.page/hotel-the-twin-towers-new-kufri",
            icon: "/icons/map-gray.svg"

        },
        {
            title: "info@hoteltwintowers.com",
            hyperLink: "mailto:info@hoteltwintowers.com",
            icon: "/icons/mail-gray.svg"
        },

    ];

    const quick_links = [
        {
            title: "About us",
            hyperLink: "/about-us",
        },
        {
            title: "Blogs",
            hyperLink: "/blogs",
        },
        {
            title: "Contact us",
            hyperLink: "/contact-us",
        },
        {
            title: "Restaurant & Bar",
            hyperLink: "/bar-and-restaurant",
        },
        {
            title: "Rooms & Suites",
            hyperLink: "/hotel-room-in-shimla",
        },
        {
            title: "Wedding",
            hyperLink: "/banquet-halls",
        },
        {
            title: "Meetings",
            hyperLink: "/conference-hall",
        },
        {
            title: "Gallery",
            hyperLink: "/gallery",
        },
    ];

    const casestudies = [
        {
            title: "Cancellation & Refund Policy",
            hyperLink: "/cancellation-and-refundpolicies",
        },
        {
            title: "COVID Policy",
            hyperLink: "/covid-policy",
        },
        {
            title: "Privacy Policy",
            hyperLink: "/privacy-policy",
        },
        {
            title: "Terms & Conditions",
            hyperLink: "/terms-and-conditions",
        },
    ];

    const currentYear = new Date().getFullYear();

    return (
        <>
            <footer className={`${styles.footer_sec} footer_scroll`} data-speed="1">
                <div className="container">
                    <div className={`${styles.footer_upper} row`}>
                        <div className={`${styles.footer_column} col`}>
                            <ul>
                                <li className={styles.footer_social}>
                                    <Link href="/">
                                        <Image src="/icons/footer-logo.svg" height={150} width={250} alt="Footer Logo" />
                                    </Link>
                                    <div className={styles.footer_social_links}>
                                        <div className={styles.icon}>
                                            <Link className={styles.fb} href="https://www.facebook.com/twintower.hotel" target="_blank" aria-label="Follow us on Facebook">
                                                <FaFacebookF />
                                            </Link>
                                        </div>
                                        <div className={styles.icon}>
                                            <Link href="https://www.instagram.com/hotel_twintowers" className={styles.insta} target="_blank" aria-label="Follow us on Instagram">
                                                <FaInstagram />
                                            </Link>
                                        </div>
                                        <div className={styles.icon}>
                                            <Link className={styles.linkedin} href="https://www.linkedin.com/in/the-twin-towers-hotel" target="_blank" aria-label="Follow us on Linkedin">
                                                <FaLinkedinIn />
                                            </Link>
                                        </div>
                                        <div className={styles.icon}>
                                            <Link href="https://www.youtube.com/@thetwintowershotel" target="_blank" aria-label="Follow us on Youtube">
                                                <FaYoutube />
                                            </Link>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>

                        <div className={`${styles.footer_column} col`}>
                            <p>Quick Links</p>
                            <ul>
                                {quick_links.map((item, index) => (
                                    <li key={index}>
                                        <Link href={item.hyperLink}>
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className={`${styles.footer_column} col`}>
                            <p>Legal</p>
                            <ul>
                                {casestudies.map((item, index) => (
                                    <li key={index}>
                                        <Link href={item.hyperLink}>
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className={`${styles.footer_column} col`}>
                            <p>Contact Us</p>
                            <ul>
                                <li className={styles.contect_info}>
                                    <Image src="/icons/call-gray.svg" width={18} height={18} className={`${styles.footer_icon} mt-2`} alt="Reservation Image" />
                                    <div>
                                        <Link href="tel:9318192605">+91 93181-92605</Link><br />
                                        <Link href="tel:9317192601">+91 93171-92601</Link><br />
                                        {/* <Link href="tel:9317192605">+91 93171-92605</Link><br /> */}
                                        <Link href="tel:9318192601">+91 93181-92601</Link>
                                    </div>
                                </li>
                                {contact.map((item, index) => (
                                    <li key={index}>
                                        <Link href={item.hyperLink}>
                                            <span>
                                                <Image src={item.icon} width={18} height={18} className={styles.footer_icon} alt="social-media-icon" />
                                            </span>
                                            <p href={item.hyperLink}>
                                                {item.title}
                                            </p>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className={`${styles.footer_column} col ${styles.footer_tripAdv}`}>
                            <TripAdvisorWidget />
                        </div>
                    </div>
                    <div className={styles.footer_bottom}>
                        <div className={styles.footer_bottom_left}>
                            <p>&#169; {currentYear} The Twin Towers Hotel. All Rights Reserved.</p>
                        </div>
                        <div className={styles.footer_bottom_right}>
                            <p> <span className={styles.footer_p}> Powered By:</span>
                                <Link href="https://unmeteredtechnologies.com/" target="_blank" rel="nofollow">
                                    <Image src="/icons/unmetered-logo.gif" width={79} height={16} alt="Company logo" />
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
}
export default Footer;
