import { useEffect, useState } from "react";
import styles from "../../styles/pages/Home.module.scss"
import Link from "next/link";
import { useRouter } from "next/router";
import moment from "moment";
import { addDays, differenceInDays } from 'date-fns';
import NewLoader from "../Loader/NewLoader";

export default function BookNowButton({ setIsactive, style }) {
    const router = useRouter();
    const [loader, setLoader] = useState(false);
    const [noOfNights, setNoOfNights] = useState(router.query.npOfNights || 1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(() => {
        const tomorrow = addDays(new Date(), 1);
        return tomorrow;
    });

    const [checkin, setCheckin] = useState({
        day: router.query.checkinDay || "",
        month: router.query.checkinMonth || "",
        year: router.query.checkinYear || "",
    });

    const [checkout, setCheckout] = useState({
        day: router.query.checkoutDay || "",
        month: router.query.checkoutMonth || "",
        year: router.query.checkoutYear || "",
    });

    useEffect(() => {
        const updateDateState = (date, setStateFunction) => {
            if (date !== null) {
                const day = date.getDate();
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const year = date.getFullYear();
                setStateFunction({ day, month, year });
            }
        };

        updateDateState(startDate, setCheckin);
        updateDateState(endDate, setCheckout);

        const nights = moment(endDate).diff(moment(startDate), 'days');
        setNoOfNights(nights > 0 ? nights : 1);
    }, [startDate, endDate]);

    useEffect(() => {
        const nights = differenceInDays(endDate, startDate);
        setNoOfNights(nights);
    }, [startDate, endDate]);

    const formated_start_date = moment(startDate).format("YYYY-MM-DD");
    const formated_end_date = moment(endDate).format("YYYY-MM-DD");

    const handleSetInactive = () => {
        if (setIsactive) {
            setIsactive(false);
        }
    };

    const handleSubmit = async (event, hotelId) => {
        event.preventDefault();

        handleSetInactive()

        try {
            await router.push(
                `/searchresults/?hotelID=${hotelId}&checkinDay=${checkin.day}&checkinMonth=${checkin.month}&checkinYear=${checkin.year}&checkoutDay=${checkout.day}&checkoutMonth=${checkout.month}&checkoutYear=${checkout.year}&room=1&adult=2&children=0&checkIn=${formated_start_date}&checkOut=${formated_end_date}&noOfNights=${noOfNights}`,
                undefined,
                { shallow: false }
            );


        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoader(false);
        }
    };

   

    return (
        <>
            {/* {loader && <NewLoader />} */}
            <button className="common_btn" style={style} onClick={(event) => handleSubmit(event, 17)}>Book Now</button>
        </>
    )
}