import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import styles from "./Search.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Image from "next/image";
import { addDays } from 'date-fns';
import Loader from "../Loader/Loader";
import NewLoader from "../Loader/NewLoader";

function SingleHotelsearchform(props) {
    const router = useRouter();
    const queryParams = router.query;
    const token = props?.hotel_token;

    const [openOptions, setOpenOptions] = useState(false);
    const optionsRef = useRef(null);
    const [loader, setLoader] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(() => {
        const tomorrow = addDays(new Date(), 1);
        return tomorrow;
    });

    const [property, setProperty] = useState('');
    const [property_city, setProperty_city] = useState(queryParams.property_city || '');
    const [hotelID, setHotelID] = useState(17);
    const [adult, setAdults] = useState(Number(queryParams.adult) || 2);
    const [children, setChildrens] = useState(Number(queryParams.children) || 0);
    const [rooms, setRooms] = useState(Number(queryParams.room) || 1);
    const [noOfNights, setNoOfNights] = useState(queryParams.npOfNights || 1);
    const [childAge, setChildAge] = useState([]);
    const [updatedRooms, setUpdatedRooms] = useState(1);

    const [checkin, setCheckin] = useState({
        day: router.query.checkinDay || "",
        month: router.query.checkinMonth || "",
        year: router.query.checkinYear || "",
    });

    const [checkout, setCheckout] = useState({
        day: router.query.checkoutDay || "",
        month: router.query.checkoutMonth || "",
        year: router.query.checkoutYear || "",
    });

    useEffect(() => {
        const updateDateState = (date, setStateFunction) => {
            if (date !== null) {
                const day = date.getDate();
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const year = date.getFullYear();
                setStateFunction({ day, month, year });
            }
        };

        updateDateState(startDate, setCheckin);
        updateDateState(endDate, setCheckout);

        const nights = moment(endDate).diff(moment(startDate), 'days');
        setNoOfNights(nights > 0 ? nights : 1);

    }, [startDate, endDate]);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        const dateRange = `${moment(start).format("YYYY-MM-DD")}+${moment(
            end
        ).format("YYYY-MM-DD")}`;
        ;
    };

    const formated_start_date = moment(startDate).format("YYYY-MM-DD");
    const formated_end_date = moment(endDate).format("YYYY-MM-DD");

    const checkin_out_date = moment(startDate).format('YYYY-MM-DD') + '+' + moment(endDate).format('YYYY-MM-DD')

    const onAdultIncrease = () => {
        const newAdult = adult + 1;
        setAdults(newAdult);
        if (newAdult > 1 && newAdult % 2 === 0 && rooms < newAdult / 2) {
            setRooms(rooms + 1);
        }
    };

    const onAdultDecrease = () => {
        if (adult > 1) {
            const newAdult = adult - 1;
            setAdults(newAdult);
            if (newAdult % 2 !== 0 && rooms > 1) {
                setRooms(rooms - 1);
            }
        }
    };

    const handleChildrenAgeChange = (index, event) => {
        const ages = [...childAge];
        ages[index] = event.target.value;
        setChildAge(ages);
    };

    const onChildDecrease = (e) => {
        e.preventDefault();
        // if (children > 0) {
        setChildrens(children - 1);
        setChildAge(childAge.slice(0, childAge.length - 1));
        // }
    };

    const onChildIncrease = (e) => {
        e.preventDefault();
        // if (children < 2) {
        setChildrens(children + 1);
        setChildAge([...childAge, ""]);
        // }
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (router.query.checkinDay && router.query.checkinMonth && router.query.checkinYear && router.query.checkoutDay && router.query.checkoutMonth && router.query.checkoutYear) {

            const { checkinDay, checkinMonth, checkinYear, checkoutDay, checkoutMonth, checkoutYear } = router.query;

            const formatDateToString = (year, month, day) => {
                const paddedMonth = String(month).padStart(2, '0');
                const paddedDay = String(day).padStart(2, '0');
                return `${year}-${paddedMonth}-${paddedDay}`;
            };

            const checkInDateString = formatDateToString(checkinYear, checkinMonth, checkinDay);
            const checkOutDateString = formatDateToString(checkoutYear, checkoutMonth, checkoutDay);

            const checkInDate = new Date(checkInDateString);
            const checkOutDate = new Date(checkOutDateString);

            setStartDate(checkInDate);
            setEndDate(checkOutDate);
        }
    }, [router.query.checkinDay, router.query.checkinMonth, router.query.checkinYear, router.query.checkoutDay, router.query.checkoutMonth, router.query.checkoutYear]);

    useEffect(() => {
        if (Object.keys(router.query).length > 0) {
            const childrenCount = parseInt(router.query.children) || 0;
            const childAges = [];

            if (childrenCount > 0) {
                for (let i = 1; i <= childrenCount; i++) {
                    const childAgeKey = `childAge[${i}]`;
                    if (router.query.hasOwnProperty(childAgeKey)) {
                        childAges.push(router.query[childAgeKey]);
                    }
                }
            }

            setAdults(Number(router.query.adult) || 2);
            setChildrens(Number(router.query.children) || 0);
            setRooms(Number(router.query.room) || 1);
            setNoOfNights(router.query.npOfNights || 1);
            setChildAge(childAges);
        }

        // setChildAge(childAges);

    }, [router.query]);

    // useEffect(() => {
    //     if (router.query.checkinDay && router.query.checkinMonth && router.query.checkinYear && router.query.checkoutDay && router.query.checkoutMonth && router.query.checkoutYear) {
    //         const { checkinDay, checkinMonth, checkinYear, checkoutDay, checkoutMonth, checkoutYear } = router.query;

    //         const checkInDate = new Date(`${checkinYear}-${checkinMonth}-${checkinDay}`);
    //         const checkOutDate = new Date(`${checkoutYear}-${checkoutMonth}-${checkoutDay}`);

    //         const formattedCheckIn = formatDate(checkInDate);
    //         const formattedCheckOut = formatDate(checkOutDate);

    //         setStartDate(new Date(formattedCheckIn));
    //         setEndDate(new Date(formattedCheckOut));
    //     }
    // }, [router.query.checkinDay, router.query.checkinMonth, router.query.checkinYear, router.query.checkoutDay, router.query.checkoutMonth, router.query.checkoutYear]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let hasChildAgeError = false;

        const sanitizedChildAges = childAge.map(age => age !== '' ? age : null).filter(age => age !== null);

        const childAgeParams = sanitizedChildAges
            .map((age, index) => {
                if (age > 12) {
                    alert("Child age must be between 0 and 12");
                    hasChildAgeError = true;
                    return;
                }
                return `&childAge[${index + 1}]=${age}`;
            })
            .join('');

        if (hasChildAgeError) {
            return;
        }
        if (endDate <= startDate) {
            alert("Check out must be a date after check in");
            return;
        }
        setLoader(true);

        try {
            await router.push(
                `/searchresults/?hotelID=${hotelID}&checkinDay=${checkin.day}&checkinMonth=${checkin.month}&checkinYear=${checkin.year}&checkoutDay=${checkout.day}&checkoutMonth=${checkout.month}&checkoutYear=${checkout.year}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&checkIn=${formated_start_date}&checkOut=${formated_end_date}&noOfNights=${noOfNights}#rooms-details`,
                undefined,
                { shallow: false }
            );
            setLoader(false);
            setOpenOptions(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (optionsRef.current && !optionsRef.current.contains(event.target)) {
            setOpenOptions(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    return (
        <div className="search_form">
            {loader && <NewLoader />}
            <form className={`${styles.homepage_search_box}`} onSubmit={(event) => handleSubmit(event)}>
                <div className={`${styles.singlehotel_searchbox_child}`}>
                    <p style={{ textAlign: 'left', height: '18px' }}>Check-In Check-Out</p>
                    <DatePicker
                        name="date_range"
                        placeholderText='Please select a date'
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        className="date_input_form"
                        endDate={endDate}
                        minDate={new Date()}
                        selectsRange
                    />
                </div>

                <div className={`${styles.singlehotel_searchbox_child}`} style={{ border: 'none' }}>
                    <p style={{ textAlign: 'left', height: '20px' }}>Adults - Rooms - Children</p>
                    <div className={styles.search_item} style={{ position: "relative" }}>
                        <span
                            onClick={() => setOpenOptions(!openOptions)}
                            className="headerSearchText"
                            style={{ width: '100%' }}
                        >{`${adult} adult · ${rooms} rooms · ${children} children`}</span>

                        {openOptions && (
                            <div className="options" ref={optionsRef}>
                                <div className="optionItem">
                                    <span className="optionText">Adult</span>
                                    <div className="optionCounter">
                                        <button
                                            type="button"
                                            disabled={adult <= 1}
                                            className="optionCounterButton"
                                            onClick={onAdultDecrease}
                                        >
                                            -
                                        </button>
                                        <input name="adult" className="optionCounterNumber" value={adult} readOnly />
                                        <button
                                            type="button" className="optionCounterButton"
                                            onClick={onAdultIncrease}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div className="optionItem">
                                    <span className="optionText">Room</span>
                                    <div className="optionCounter">
                                        <button
                                            type="button"
                                            disabled={rooms <= 1}
                                            className="optionCounterButton"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRooms(rooms - 1);
                                            }}
                                        >
                                            -
                                        </button>
                                        <input name="rooms" className="optionCounterNumber" value={rooms} readOnly />
                                        <button
                                            type="button"
                                            className="optionCounterButton"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRooms(rooms + 1);
                                            }}                                        >
                                            +
                                        </button>
                                    </div>
                                </div>

                                <div className="optionItem">
                                    <span className="optionText">Children</span>
                                    <div className="optionCounter">
                                        <button
                                            type="button"
                                            disabled={children <= 0}
                                            className="optionCounterButton"
                                            onClick={onChildDecrease}
                                        >
                                            -
                                        </button>
                                        <input name="children" className="optionCounterNumber" value={children} readOnly />
                                        <button
                                            type="button"
                                            className="optionCounterButton"
                                            onClick={onChildIncrease}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                {childAge.map((age, index) => (
                                    <div className="optionItem" key={index}>
                                        <span className="optionText">Child {index + 1} Age</span>
                                        <div className="optionCounter">
                                            <input
                                                className="childAge_input"
                                                type="number"
                                                name="childAge[]"
                                                min="1"
                                                // max="12"
                                                value={age}
                                                onChange={(event) =>
                                                    handleChildrenAgeChange(index, event)
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className={`${styles.searchbox_btn_child}`}>
                    {/* <button type="submit" className={styles.submit_btn}>
                        <Image src="/icons/search-white.svg" alt="Search ICon" width={25} height={25}></Image>
                    </button> */}
                    <button type="submit" className={`${styles.submit_btn}`}>
                        <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg"><title>This is a icon</title>
                            <path d="M11.3448 22.1897C17.0581 22.1897 21.6897 17.5581 21.6897 11.8448C21.6897 6.13154 17.0581 1.5 11.3448 1.5C5.63154 1.5 1 6.13154 1 11.8448C1 17.5581 5.63154 22.1897 11.3448 22.1897Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
                            <path d="M18.2414 18.7414L26 26.5" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
                        </svg>
                    </button>
                    <button type="submit" className={styles.submit_btn_mobile}>
                        Search Rooms
                    </button>
                </div>
            </form>
        </div>
    );
}

export default SingleHotelsearchform;