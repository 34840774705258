import React from "react";
import Head from "next/head";
import styles from "./Loader.module.scss"

export default function Loader() {
    return (
        <>
            <section className={styles.loader_main} role="alertdialog" aria-busy="true" aria-live="polite" aria-label="Loading…">
                <div className={styles.loader} aria-hidden="true">
                    <div className={styles.loader__sq}></div>
                    <div className={styles.loader__sq}></div>
                </div>
                <div className={styles.loading_text}></div>
            </section>
        </>
    )
}